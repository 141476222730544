@import "./fonts/_fonts";
@import "./reset";

$default-font-size: 1.05em;

html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
}

$black: #222;
$rose: #e89182;

h3 {
  font-family: HelveticaNeue-Thin;
  font-size: 2.5em;
  font-weight: 100;
  text-align: right;
  color: $black;
  margin: 0;
}

a {
  font-size: $default-font-size;
  color: $rose;
}


.main-container {
  max-width: 1090px;
  display: flex;
  max-height: 546px;
  align-items: flex-end;
  justify-content: center;

  &__left {
    max-width: 400px;
    margin-bottom: 20px;
    text-align: right;

    .header {
      font-family: NoeDisplay-Bold;
      font-size: 2.5em;
      font-weight: bold;
      margin: 0;
      margin-top: 10px;
      line-height: 2.5rem;
    }
    .description {
      color: $black;
      font-family: HelveticaNeue-Light;
      font-size: $default-font-size;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 30px;
      line-height: 1.5;
    }
    .links {
      display: flex;
      justify-content: flex-end;
      a {
        display: block;
        font-family: HelveticaNeue-Light;
        line-height: 1.5;
        margin-left: 30px;
        font-weight: 500;
        font-size: $default-font-size;
      }
    }
  }
  &__right {
    max-width: 546px;
    margin-left: 50px;

    img {
      width: 100%;
    }
  }
}

.green {
  color: #156051;
}

.bold {
  font-weight: 500;
  font-family: HelveticaNeue-Medium;
}


@media screen and (min-width: 1024px) {
  .circle-green {
    display: none;
  }
  .circle-rose {
    display: none;
  }
  .points {
    display: none;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
  .circle-green {
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
  }
  .circle-rose {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 330px;
    z-index: 1;
  }
  .points {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 110px;
    z-index: 0;
  }

  .main-container {
    a {
      font-size: 14px;
      color: $rose;
    }
    &__left {
      .header {
        font-family: NoeDisplay-Bold;
        font-size: 39px;
      }

      .description { 
        font-size: $default-font-size;
        max-width: 253px;
      }

      .links {
        justify-content: space-around;
        a {
          font-size: $default-font-size;
          margin-left: 0px;
        }
      }
    }
    &__right {
      display: none;
    }
  }
}
