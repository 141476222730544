@font-face {
  font-family: 'HelveticaNeue-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Helvetica Thin'), url('fonts/HelveticaNeue-Thin.woff') format('woff');
}
@font-face {
  font-family: 'HelveticaNeue-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Helvetica Light'), url('fonts/HelveticaNeue-Light.woff') format('woff');
}
@font-face {
  font-family: 'HelveticaNeue-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Helvetica Medium'), url('fonts/HelveticaNeue-Medium.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Helvetica Bold'), url('fonts/HelveticaNeue-Bold.woff') format('woff');
}


@font-face {
  font-family: 'NoeDisplay-Bold';
  src: url('fonts/NoeDisplay/NoeDisplay-Bold.eot');
  src: url('fonts/NoeDisplay/NoeDisplay-Bold.woff2') format('woff2'),
       url('fonts/NoeDisplay/NoeDisplay-Bold.woff') format('woff'),
       url('fonts/NoeDisplay/NoeDisplay-Bold.ttf') format('truetype'),
       url('fonts/NoeDisplay/NoeDisplay-Bold.svg#NoeDisplay-Bold') format('svg'),
       url('fonts/NoeDisplay/NoeDisplay-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}